





































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { UpdateSemesterFormPayload } from '@/models/semesters';
import { getDifferenceInMinutes } from '@/utils/moment';
import moment from 'moment';
@Component({
  components: { Dropdown },
})
export default class SemesterFormTemplate extends Vue {
  // UI state
  currentId = -1;
  minimumDate = new Date();
  isSaveButtonEnabled = false;
  // Values
  selectedSemesterName = ``;
  selectedSemesterDescription = ``;
  selectedSemesterStartDate: Date | null = null;
  selectedSemesterEndDate: Date | null = null;

  @Prop()
  isEditMode!: boolean;

  @Prop()
  previousValue!: UpdateSemesterFormPayload;

  mounted() {
    this.repopulateValue();
  }

  repopulateValue() {
    if (this.isEditMode) {
      this.currentId = this.previousValue.id;
      this.selectedSemesterName = this.previousValue.name;
      this.selectedSemesterDescription = this.previousValue.description;
      this.selectedSemesterStartDate = moment(this.previousValue.startdate).toDate();
      this.selectedSemesterEndDate = moment(this.previousValue.enddate).toDate();
    }
  }


  setSemesterName() {
    this.validateInputs();
  }

  setSemesterDescription() {
    this.validateInputs();
  }

  validateInputs() {
    // Base
    let baseValidation =
      this.selectedSemesterStartDate !== null &&
      this.selectedSemesterEndDate !== null &&
      this.selectedSemesterName.length !== null;
  
    this.isSaveButtonEnabled = baseValidation;
  }

  resetEndDate() {
    if (
      getDifferenceInMinutes(this.selectedSemesterStartDate, this.selectedSemesterEndDate) < 0
    ) {
      this.selectedSemesterEndDate = null;
    }
    this.validateInputs();
  }

  @Emit(`update`)
  returnValue() {
    const payload: UpdateSemesterFormPayload = {
      id: this.currentId,
      name: this.selectedSemesterName,
      description: this.selectedSemesterDescription || ``,
      startdate: moment(this.selectedSemesterStartDate).format(`YYYY-MM-DD`), 
      enddate: moment(this.selectedSemesterEndDate).format(`YYYY-MM-DD`),
    };

    return payload;
  }
}
