import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { PromotionDataResponse, PromotionListFetchParams, PromotionResponse } from '@/models/promotion';

@Module({
    namespaced: true,
    dynamic: true,
    name: `promotion.list`,
    store,
})
class PromotionListStore extends VuexModule {
    isLoading = false;
    error: any = null;
    response: PromotionDataResponse | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: PromotionDataResponse | null) {
        this.response = payload;
    }

    @Action
    retrievePromotions(params?: Partial<PromotionListFetchParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);

        axios.get<PromotionResponse>(`/promoCode`, {
            params: params,
        }).then((response) => {
            if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
            }

            if (response.data.code >= 400) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching promotion data. Please try again`,
                });
            }
        })
        .catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(PromotionListStore);