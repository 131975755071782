


































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { SettingStores } from '@/store/settings';
import { SettingDetails } from '@/models/settings';
import SettingsFormTemplate from '@/components/settings/SettingsFormTemplate.vue';
import { PromotionStores } from '@/store/promotion';
import { PromotionCodeCreateBaseDetails, PromotionCodeDetails, PromotionCodeUpdateBaseDetails } from '@/models/promotion';
import PromotionTable from '@/components/promotion/PromotionTable.vue';
import SemesterTable from '@/components/semester/SemesterTable.vue';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import PromotionFormTemplate from '@/components/promotion/PromotionFormTemplate.vue';
import SemesterFormTemplate from '@/components/semester/SemesterFormTemplate.vue';
import moment from 'moment';
import StudyLevel from '@/components/study_level/StudyLevel.vue';
import { StudyLevelStores } from '@/store/study_level';
import { StudyLevelCreateBaseDetails, StudyLevelDetails, StudyLevelUpdateBaseDetails } from '@/models/study_level';
import { CreateSemesterFormPayload, SemesterDetails, UpdateSemesterFormPayload } from '@/models/semesters';
import { SemesterStores } from '@/store/semesters';


@Component({
    components: {
        Page, 
        Dropdown,
        SettingsFormTemplate,
        PromotionTable,
        EmptyPlaceholder,
        PromotionFormTemplate,
        StudyLevel,
        SemesterTable,
        SemesterFormTemplate
    }
})
export default class Settings extends Vue {
    // UI State
    selectedTab = 0;
    isPromotionEditMode = false;
    isSemesterEditMode = false;
    // Settings
    currentSettingDetail: SettingDetails = {
        id: -1,
        coursePurchaseCutoffDate: -1,
        adminEmail: ``,
        supportEmail: ``,
        careerEmail: ``,
        salesEmail: ``
    };
    //Promotion
    promotionCodes: PromotionCodeDetails[] = [];
    isPromotionModalActive = false;
    currentPromotionCode: PromotionCodeUpdateBaseDetails = {
        id: -1,
        code: ``,
        promocodetypeid: -1,
        value: 0,
        startdate: 0,
        enddate: 0,
        usage_limit: null,
    };

    //Semester
    semesters: SemesterDetails[] = [];
    isSemesterModalActive = false;
    currentSemester: UpdateSemesterFormPayload = {
        id: 0,
        name: ``,
        description: ``,
        startdate: '',
        enddate: '',
    };
    // Pagination for Semester  
    currentSemesterPage = 1;
    currentSemesterLimit = 10;
    searchSemesterQuery = ``;
    currentSemesterTotalCount = 0;
    // Store
    settingStore = SettingStores.detail;
    promotionListStore = PromotionStores.list;
    promotionCreateStore = PromotionStores.create;
    promotionEditStore = PromotionStores.detail;
    studyLevelListStore = StudyLevelStores.list;
    studyLevelCreateStore = StudyLevelStores.create;
    studyLevelEditStore = StudyLevelStores.detail;
    semesterStores = SemesterStores;

    studyLevels: StudyLevelDetails[] = [];
    // Pagination for Promotion
    currentPage = 1;
    currentLimit = 10;
    searchQuery = ``;
    filter = 0;
    currentTotalCount = 0;
    // Study Level
    showStudyLevelForm = false;
    
    toggleStudyLevelForm(payload: boolean) {
        this.showStudyLevelForm = payload;
    }
    // Promotion
    get tableData() {
        return this.promotionCodes;
    }

    // Semester
    get semesterData() {
        return this.semesters;
    }

    get totalCount () {
        return this.currentTotalCount;
    }

    get isCreateButtonShown() {
        return this.promotionCodes.length < 10;
    }

    // Settings
    get currentMonth() {
        return `January - December`;
    }

    get emailTypes() {
        return [
            `Admin`,
            `Careers`,
            `Sales`,
            `Support`
        ];
    }

    get dates() {
        const dateNumberData: DropdownItem[] = [];
        const numberOfDays = 25;
        for (let index = 5; index <= numberOfDays; index++) {
            const day = this.formatDateNumber(index);
            dateNumberData.push({
                text: day,
                value: index.toString(),
                uniqueKey: `${index}_`
            });
        }
        return dateNumberData;
    }

    get settingDetails() {
        return this.currentSettingDetail;
    }

    mounted() {
        this.fetchData();
    }

    // General
    fetchData() {
        if (this.selectedTab === 0) {
            this.promotionListStore.retrievePromotions({
                page: this.currentPage,
                limit: this.currentLimit,
            });
        }

        if (this.selectedTab === 1) {
            this.studyLevelListStore.retrieveStudyLevels({
                page: this.currentPage,
                limit: 100,
            });
        }

        if (this.selectedTab === 2) {
            this.semesterStores.getSemesters({
                page: this.currentSemesterPage,
                limit: this.currentSemesterLimit,
            });
        }

        if (this.selectedTab === 3) {
            this.settingStore.retrieveSettingDetails();
        }
    }

    // Promotion Tab
    @Watch(`promotionListStore.response`)
    retrievePromotionCodes() {
        this.promotionCodes = [];
        if (this.promotionListStore.response) {
            this.currentTotalCount = this.promotionListStore.response.totalCount;
            if (this.promotionListStore.response!.objects.length > 0) {
                this.promotionCodes = this.promotionListStore.response.objects;
            }
        }
    }

    // Semester Tab
    @Watch(`semesterStores.semesters`)
    retrieveSemesters() {
        this.semesters = [];
        if (this.semesterStores.semesters.data) {
            this.currentSemesterTotalCount = this.semesterStores.semesters.totalRecords;
            if (this.semesterStores.semesters!.data.length > 0) {
                this.semesters = this.semesterStores.semesters.data;
            }
        }
    }

    // Setting Tab
    @Watch(`settingStore.settingDetails`)
    updateData() {
        if (this.settingStore.settingDetails) {
            this.currentSettingDetail = this.settingStore.settingDetails;
        }
    }

    // Study Level Tab
    @Watch(`studyLevelListStore.response`)
    retrieveStudyLevels() {
        this.studyLevels = [];
        if (this.studyLevelListStore.response) {
            this.currentTotalCount = this.studyLevelListStore.response.totalCount;
            if (this.studyLevelListStore.response!.objects.length > 0) {
                this.studyLevels = this.studyLevelListStore.response.objects;
            }
        }
    }

    updateSettingDetails(payload: SettingDetails) {
        this.settingStore.updateSettingDetails(payload);
    }

    @Watch(`settingStore.response`)
    redirectToDashboard() {
        if (this.settingStore.response) {
            this.$router.push({
                path: `/dashboard`
            });
        }
    }

    formatDateNumber(value: number): string {
        if (value > 10 && value < 14) {
            return value.toString() + `th`;
        }
        let date = ``;
        switch(value % 10) {
            case 1: {
                date = value.toString() + `st`;
                break;
            }
            case 2: {
                date = value.toString() + `nd`;
                break;
            }
            case 3: {
                date = value.toString() + `rd`;
                break;
            }
            default: {
                date = value.toString() + `th`;
                break;
            }
        }
        return date;
    }

    commitStudyLevelUpdate(payload: StudyLevelUpdateBaseDetails) {
        if(payload.id) {
            const updatePayload: StudyLevelUpdateBaseDetails = {
                id: payload.id,
                name: payload.name
            };
            this.studyLevelEditStore.updateCurrentStudyLevel(updatePayload);
        } else {
            const createPayload: StudyLevelCreateBaseDetails = {
                name: payload.name
            };
            this.studyLevelCreateStore.createNewStudyLevel(createPayload);
        }
    }
    
    commitStudyLevelDelete(payload: StudyLevelDetails) {
        this.studyLevelEditStore.deleteCurrentStudyLevel(payload.id);
    }

    @Watch(`studyLevelCreateStore.response`)
    checkStudylLevelCreateResponse() {
        if (this.studyLevelCreateStore.response) {
            this.fetchData();
        }
    }

    @Watch(`studyLevelEditStore.response`)
    checkStudylLevelEditResponse() {
        if (this.studyLevelEditStore.response) {
            this.fetchData();
        }
    }

    @Watch(`studyLevelEditStore.deleteResponse`)
    checkStudyLevelDeleteResponse() {
        if (this.studyLevelEditStore.deleteResponse) {
            this.fetchData();
        }
    }

    commitPromotionCodeUpdate(payload: PromotionCodeUpdateBaseDetails) {
        if (!this.isPromotionEditMode) {
            const createPayload: PromotionCodeCreateBaseDetails = {
                value: payload.value,
                code: payload.code,
                promocodetypeid: payload.promocodetypeid,
                startdate: payload.startdate,
                enddate: payload.enddate,
                usage_limit: payload.usage_limit,
            };
            this.isPromotionEditMode = false;
            this.promotionCreateStore.createNewPromotion(createPayload);
        }

        if (this.isPromotionEditMode) {
            const updatePayload: PromotionCodeUpdateBaseDetails = {
                id: payload.id,
                value: payload.value,
                code: payload.code,
                promocodetypeid: payload.promocodetypeid,
                startdate: payload.startdate,
                enddate: payload.enddate,
                usage_limit: payload.usage_limit,
            };
            this.promotionEditStore.updateCurrentPromotion(updatePayload);
        }
    }

    commitSemesterUpdate(payload: CreateSemesterFormPayload | UpdateSemesterFormPayload) {
        // check if start date and end date overlaps with other semesters
        const startDate = moment(payload.startdate).format('YYYY-MM-DD');
        const endDate = moment(payload.enddate).format('YYYY-MM-DD');
        const isOverlapping = this.semesters.some((semester) => {
            const semesterStartDate = moment(semester.startdate).format('YYYY-MM-DD');
            const semesterEndDate = moment(semester.enddate).format('YYYY-MM-DD');
            return (startDate >= semesterStartDate && startDate <= semesterEndDate) || (endDate >= semesterStartDate && endDate <= semesterEndDate);
        });

        if (isOverlapping && !this.isSemesterEditMode) {
            this.$buefy.toast.open({
                message: `Semester dates are overlapping with other semesters`,
                type: `is-danger`,
            });
            return;
        }
        
        if (!this.isSemesterEditMode) {
            const createPayload: CreateSemesterFormPayload = {
                name: payload.name,
                description: payload.description,
                startdate: payload.startdate,
                enddate: payload.enddate,
            };
            this.isSemesterEditMode = false;
            this.semesterStores.createSemester(createPayload);
        }

        if (this.isSemesterEditMode) {
            const updatePayload: UpdateSemesterFormPayload = {
                id: (payload as any).id,
                name: payload.name,
                description: payload.description,
                startdate: payload.startdate,
                enddate: payload.enddate,
            };
            this.semesterStores.updateSemester(updatePayload);
        }
    }

    @Watch(`promotionCreateStore.response`)
    checkCreateResponse() {
        if (this.promotionCreateStore.response) {
            this.isPromotionModalActive = false;
            this.fetchData();
        }
    }

    editPromotionCode(payload: PromotionCodeDetails) {
        this.isPromotionEditMode = true;
        this.isPromotionModalActive = true;
        this.currentPromotionCode = {
            id: payload.id,
            code: payload.code,
            promocodetypeid: payload.type.id,
            value: Number(payload.value),
            startdate: moment(payload.startdate, 'YYYY-MM-DD hh:mm:ss a').toDate().getTime(),
            enddate: moment(payload.enddate, 'YYYY-MM-DD hh:mm:ss a').toDate().getTime(),
            usage_limit: payload.usage_limit,
        };
    }

    editSemester(payload: SemesterDetails) {
        this.isSemesterEditMode = true;
        this.isSemesterModalActive = true;
        this.currentSemester = {
            id: payload.id,
            name: payload.name,
            description: payload.description,
            startdate: moment(payload.startdate, 'YYYY-MM-DD hh:mm:ss a').format('YYYY-MM-DD'),
            enddate: moment(payload.enddate, 'YYYY-MM-DD hh:mm:ss a').format('YYYY-MM-DD'),
        };
    }

    @Watch(`semesterStores.isUpdatingSemester`)
    checkEditSemesterResponse() {
        if (!this.semesterStores.isUpdatingSemester) {
            this.isSemesterEditMode = false;
            this.isSemesterModalActive = false;
            this.fetchData();
        }
    }

    @Watch(`semesterStores.isCreatingSemester`)
    checkCreateSemesterResponse() {
        if (!this.semesterStores.isCreatingSemester) {
            this.isSemesterModalActive = false;
            this.fetchData();
        }
    }

    @Watch(`promotionEditStore.response`)
    checkEditResponse() {
        if (this.promotionEditStore.response) {
            this.isPromotionEditMode = false;
            this.isPromotionModalActive = false;
            this.fetchData();
        }
    }

    deletePromotionCode(payload: PromotionCodeDetails) {
        this.promotionEditStore.deleteCurrentPromotion(payload.id);
    }

    @Watch(`promotionEditStore.deleteResponse`)
    checkDeleteResponse() {
        if (this.promotionEditStore.deleteResponse) {
            this.fetchData();
        }
    }

    resetPromotionState() {
        this.isPromotionEditMode = false;
    }

    resetSemesterState() {
        this.isSemesterEditMode = false;
    }
}
