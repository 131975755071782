




































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { PromotionCodeUpdateBaseDetails } from '@/models/promotion';
import { getDifferenceInMinutes } from '@/utils/moment';
import moment from 'moment';
@Component({
  components: { Dropdown },
})
export default class PromotionFormTemplate extends Vue {
  // UI state
  currentId = -1;
  selectedPromotionType = '- Select - ';
  previousSelectedPromotionType = '';
  isValueUnlimited = false;
  isValueNotUnlimited = false;
  isValueInvalid = false;
  isPercentageValueInvalid = false;
  isUsageLimitInvalid = false;
  minimumDate = new Date();
  isCodeNameInvalid = false;
  isSaveButtonEnabled = false;
  // Values
  selectedPromotionTypeValue = -1;
  selectedPromotionCodeName = ``;
  selectedPromotionValue: number | null = null;
  selectedPromotionStartDate: Date | null = null;
  selectedPromotionEndDate: Date | null = null;
  selectedUsageLimit: number | null = null;

  @Prop()
  isEditMode!: boolean;

  @Prop()
  previousValue!: PromotionCodeUpdateBaseDetails;

  get isValueInputDisabled() {
    return this.selectedPromotionTypeValue < 1;
  }

  get dropdownOptions(): DropdownItem[] {
    return [
      {
        text: `Value`,
        value: '1',
        uniqueKey: `value_1`,
      },
      {
        text: `Percentage`,
        value: '2',
        uniqueKey: `percentage_2`,
      },
    ];
  }

  get invalidValueErrorMessage() {
    return `Value should be only whole numbers and cannot be negative`;
  }

  get invalidValuePercentageErrorMessage() {
    return `Percentage value cannot exceed more than 100 and cannot be less than 0 and only whole numbers`;
  }

  get invalidNameErrorMessage() {
    return `Promotion Code Name should be within 20 characters`;
  }

  mounted() {
    this.repopulateValue();
  }

  repopulateValue() {
    if (this.isEditMode) {
      this.currentId = this.previousValue.id;
      this.previousSelectedPromotionType = this.previousValue.promocodetypeid.toString();
      this.selectedPromotionValue = Number(this.previousValue.value);
      this.selectedPromotionStartDate = moment(this.previousValue.startdate).toDate();
      this.selectedPromotionEndDate = moment(this.previousValue.enddate).toDate();
      this.selectedUsageLimit = this.previousValue.usage_limit;
      this.isValueUnlimited = this.selectedUsageLimit === null;
      this.isValueNotUnlimited = this.selectedUsageLimit !== null;
      this.selectedPromotionCodeName = this.previousValue.code;
      this.selectedPromotionTypeValue = this.previousValue.promocodetypeid;
      this.validateValue(this.selectedPromotionValue);
    }
  }

  updatePromotionTypeState(payload: DropdownItem) {
    this.selectedPromotionType = payload.text;
    this.selectedPromotionTypeValue = Number(payload.value);

    this.isValueInvalid = this.selectedPromotionTypeValue === 1 ? this.isValueUnlimited : false;
    this.isPercentageValueInvalid =
      this.selectedPromotionTypeValue === 2 ? this.isPercentageValueInvalid : false;

    this.validateValue(this.selectedPromotionValue as number);
  }

  setUnlimitedState() {
    this.isValueNotUnlimited = false;
    this.selectedUsageLimit = null;
    this.validateInputs();
  }

  setNotUnlimitedState() {
    this.isValueUnlimited = false;
    this.selectedUsageLimit = null;
    this.validateInputs();
  }

  validateValue(payload: number) {
    if (this.selectedPromotionTypeValue === 1) {
      this.isValueInvalid = payload < 0 || payload % 1 !== 0;
    }

    if (this.selectedPromotionTypeValue === 2) {
      this.isPercentageValueInvalid = payload <= 0 || payload % 1 !== 0 || payload > 100;
    }

    this.validateInputs();
  }

  validateUsageLimit(payload: number) {
    this.isUsageLimitInvalid = payload <= 0 || payload % 1 !== 0;
    this.validateInputs();
  }

  setCodeName() {
    this.isCodeNameInvalid =
      this.selectedPromotionCodeName.length <= 0 || this.selectedPromotionCodeName.length > 20;
    this.validateInputs();
  }

  validateInputs() {
    // Base
    let baseValidation =
      this.selectedPromotionTypeValue > 0 &&
      this.selectedPromotionStartDate !== null &&
      this.selectedPromotionEndDate !== null &&
      this.selectedPromotionValue !== null &&
      this.selectedPromotionValue >= 0 &&
      this.selectedPromotionCodeName.length > 0 &&
      this.selectedPromotionCodeName.length <= 20 &&
      !this.isCodeNameInvalid &&
      !this.isPercentageValueInvalid &&
      !this.isValueInvalid;
    // Not Unlimited
    let notUnlimitedValidation = true;
    if (this.isValueNotUnlimited) {
      notUnlimitedValidation =
        this.selectedUsageLimit !== null &&
        this.selectedUsageLimit > 0 &&
        !this.isUsageLimitInvalid;
    }

    let isUnlimitedCheckboxesSelected = this.isValueUnlimited || this.isValueNotUnlimited;

    this.isSaveButtonEnabled =
      baseValidation && notUnlimitedValidation && isUnlimitedCheckboxesSelected;
  }

  resetEndDate() {
    if (
      getDifferenceInMinutes(this.selectedPromotionStartDate, this.selectedPromotionEndDate) < 0
    ) {
      this.selectedPromotionEndDate = null;
    }
    this.validateInputs();
  }

  @Emit(`update`)
  returnValue() {
    const payload: PromotionCodeUpdateBaseDetails = {
      id: this.currentId,
      code: this.selectedPromotionCodeName,
      value: this.selectedPromotionValue as number,
      promocodetypeid: this.selectedPromotionTypeValue,
      startdate: Math.round(this.selectedPromotionStartDate!.getTime() / 1000),
      enddate: Math.round(this.selectedPromotionEndDate!.getTime() / 1000),
      usage_limit: this.selectedUsageLimit,
    };

    return payload;
  }
}
