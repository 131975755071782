


































































































import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { EMAIL_REGEX } from '@/utils/regex';
import { SettingDetails } from '@/models/settings';
@Component({
  components: {
    Page,
    Dropdown,
  },
})
export default class Settings extends Vue {
  // UI State
  selectedDateNumber = `- Select -`;
  selectedEmails: { [key: string]: string } = {};
  selectedEmailsErrors: { [key: string]: boolean } = {};
  currentSettingDetail: SettingDetails = {
    id: -1,
    coursePurchaseCutoffDate: -1,
    adminEmail: ``,
    supportEmail: ``,
    careerEmail: ``,
    salesEmail: ``,
  };
  settingDetailChanges: SettingDetails = {
    id: -1,
    coursePurchaseCutoffDate: -1,
    adminEmail: ``,
    supportEmail: ``,
    careerEmail: ``,
    salesEmail: ``,
  };
  areEmailsValid = false;
  isModalShown = false;
  // Edit
  previousSelectedDateNumber = ``;
  dateNumber = ``;

  // Props
  @Prop({ required: true })
  monthDisplay!: string;

  @Prop({ required: true })
  specifiedEmailTypes!: string[];

  @Prop({ required: true })
  dateRangeOptions!: DropdownItem[];

  @Prop()
  previousSettingDetails!: SettingDetails;

  get currentMonth() {
    return this.monthDisplay;
  }

  get emailTypes() {
    return this.specifiedEmailTypes;
  }

  get dates() {
    return this.dateRangeOptions;
  }

  get isSaveButtonDisabled() {
    return !this.areEmailsValid || Number(this.dateNumber) < 5;
  }

  mounted() {
    this.emailTypes.forEach((data) => {
      this.selectedEmailsErrors[data] = false;
      this.selectedEmails[data] = ``;
    });
  }

  openModal() {
    this.settingDetailChanges = {
      id: this.currentSettingDetail.id,
      coursePurchaseCutoffDate: Number(this.dateNumber),
      adminEmail: this.selectedEmails[`Admin`],
      careerEmail: this.selectedEmails[`Careers`],
      salesEmail: this.selectedEmails[`Sales`],
      supportEmail: this.selectedEmails[`Support`],
    };
    this.isModalShown = true;
  }

  @Watch(`previousSettingDetails`)
  updateData() {
    if (this.previousSettingDetails) {
      this.currentSettingDetail = this.previousSettingDetails;
      this.previousSelectedDateNumber = this.currentSettingDetail.coursePurchaseCutoffDate.toString();
      this.selectedEmails[`Admin`] = this.currentSettingDetail.adminEmail;
      this.selectedEmails[`Careers`] = this.currentSettingDetail.careerEmail;
      this.selectedEmails[`Sales`] = this.currentSettingDetail.salesEmail;
      this.selectedEmails[`Support`] = this.currentSettingDetail.supportEmail;
      this.validateEmails();
    }
  }

  updateDateNumber(data: DropdownItem) {
    this.selectedDateNumber = data.text;
    this.dateNumber = data.value;
  }

  validateEmails() {
    this.areEmailsValid = true;
    this.emailTypes.forEach((item) => {
      if (!EMAIL_REGEX.test(this.selectedEmails[item]) || this.selectedEmails[item]?.length === 0) {
        this.areEmailsValid = false;
      }
    });
  }

  validateEmailErrors(emailType: string) {
    this.areEmailsValid = true;
    this.selectedEmailsErrors[emailType] = false;
    if (
      !EMAIL_REGEX.test(this.selectedEmails[emailType]) ||
      this.selectedEmails[emailType]?.length === 0
    ) {
      this.selectedEmailsErrors[emailType] = true;
    }
    this.validateEmails();
  }

  @Emit(`update`)
  updateSettingDetails() {
    const payload: SettingDetails = {
      id: this.currentSettingDetail.id,
      coursePurchaseCutoffDate: Number(this.dateNumber),
      adminEmail: this.selectedEmails[`Admin`],
      careerEmail: this.selectedEmails[`Careers`],
      salesEmail: this.selectedEmails[`Sales`],
      supportEmail: this.selectedEmails[`Support`],
    };
    return payload;
  }

  formatDateNumber(value: number): string {
    if (value > 10 && value < 14) {
      return value.toString() + `th`;
    }
    let date = ``;
    switch (value % 10) {
      case 1: {
        date = value.toString() + `st`;
        break;
      }
      case 2: {
        date = value.toString() + `nd`;
        break;
      }
      case 3: {
        date = value.toString() + `rd`;
        break;
      }
      default: {
        date = value.toString() + `th`;
        break;
      }
    }
    return date;
  }
}
