


































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { PromotionCodeDetails } from '@/models/promotion';
import { getDifferenceInMinutes } from '@/utils/moment';
import moment from 'moment';
@Component({})
export default class PromotionTable extends Vue {
  @Prop()
  tableData!: PromotionCodeDetails[];

  checkPromotionStatus(data: PromotionCodeDetails) {
    const startDate = moment(data.startdate, 'YYYY-MM-DD hh:mm:ss a').toISOString();
    const endDate = moment(data.enddate, 'YYYY-MM-DD hh:mm:ss a').toISOString();
    if (getDifferenceInMinutes(startDate, new Date()) < 0) {
      return `Not Active`;
    }

    if (getDifferenceInMinutes(new Date(), endDate) < 0) {
      return `Expired`;
    }

    return `Active`;
  }

  @Emit(`edit`)
  editItem(item: PromotionCodeDetails) {
    return item;
  }

  @Emit(`delete`)
  deleteItem(item: PromotionCodeDetails) {
    return item;
  }
}
