




































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { NO_SPECIAL_CHARACTER_REGEX } from '@/utils/regex';
import { StudyLevelCreateBaseDetails, StudyLevelDetails, StudyLevelUpdateBaseDetails } from '@/models/study_level';

@Component({})
export default class StudyLevel extends Vue {
    currentData = '';
    submitButtonDisable = true;
    showErrorText = '';
    levelEditMode = false;

    @Prop({ required: false })
    studyLevel!: StudyLevelUpdateBaseDetails;

    @Prop({ required: true })
    placeholder!: string;

    @Prop({ default: '' })
    errorText!: string;

    @Prop({ default: false })
    editMode!: boolean;
    
    mounted() {
        this.currentData = this.studyLevel ? this.studyLevel.name : '';
        this.setEditMode(this.editMode)
    }

    get displayErrorText() {
        return this.showErrorText !== ''? this.showErrorText: this.errorText;
    }
    updateCurrentName(payload: string) {
        if(NO_SPECIAL_CHARACTER_REGEX.test(payload)) {
            this.submitButtonDisable = true;
            this.showErrorText = "Special Character not allow to input.";
        } else {
            this.currentData = payload;
            this.submitButtonDisable = false;
            this.showErrorText = "";
        }
    }

    setEditMode(status: boolean) {
        this.levelEditMode = status
    }

    @Emit(`update`) 
    updateComponent() {
        if(this.studyLevel) {
            const payload: StudyLevelUpdateBaseDetails = {
                id: this.studyLevel.id,
                name: this.currentData
            };
            return payload;
        } else {
            const payload: StudyLevelCreateBaseDetails = {
                name: this.currentData
            };
            this.closeComponent();
            return payload;
        }
    }

    @Emit(`close`)
    closeComponent() {
        this.currentData = this.studyLevel ? this.studyLevel.name : '';
        this.setEditMode(false);
        return false;
    }

    @Emit(`delete`)
    deleteItem(payload: StudyLevelDetails) {
        return payload;
    }
}
